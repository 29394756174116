import React, { useState, useEffect } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { fetchAllSenders } from "../../store/categoryUserList";
// kendo
import { Checkbox } from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
// css
import "./dialog.css";

export const SenderListDialog = (props) => {
  const dispatch = useDispatch();
  const [selectedSenders, setSelectedSenders] = useState(props.defaultSelected);
  const { senders_loading, senders, senders_error } = useSelector(
    (state) => state.categoryUserList
  );
  useEffect(() => {
    dispatch(fetchAllSenders());
  }, []);
  const handleSelected = (e, val) => {
    var updatedList = [...selectedSenders];
    if (e.target.value) {
      updatedList = [...selectedSenders, val];
    } else {
      var selected_index = selectedSenders.map((v) => v.id).indexOf(val.id);
      updatedList.splice(selected_index, 1);
    }
    setSelectedSenders(updatedList);
  };
  const handleSubmitDepots = () => {
    let newSelectedSenders = senders.filter((v1)=> selectedSenders.some((v2)=> v1.username === v2.username));
    console.log(newSelectedSenders);
    props.callback(newSelectedSenders);
  };
  const isChecked = (item) =>
    selectedSenders.map((v) => v.username).includes(item) ? true : false;
  return (
    <Dialog
      title={`Please select sender(s): ${selectedSenders.length} selected`}
      width={500}
      height={500}
      onClose={props.onClickNo}
      className="sender-dialog"
    >
      <div className="sender-list-div">
        {senders_loading ? (
          <Loader
            type="infinite-spinner"
            style={{
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              justifyContent: "center",
              height: "350px",
            }}
          />
        ) : (
          senders.map((v) => (
            <Checkbox
              label={v.name}
              onChange={(e) => handleSelected(e, v)}
              checked={isChecked(v.username)}
            />
          ))
        )}
      </div>
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base sender-cancel-button"
          onClick={props.onClickNo}
        >
          No
        </button>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base sender-submit-button"
          onClick={handleSubmitDepots}
        >
          Yes
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};
